import { Component, OnInit } from '@angular/core';
import { Nanium } from 'nanium/core';
import { NaniumConsumerBrowserHttp } from 'nanium/managers/consumers/browserHttp';
import { WebRequestInterceptorService } from '../services/security/web-request-interceptor.service';
import { WebAuthenticationService } from '../services/security/web-authentication.service';
import { Router } from '@angular/router';
import { User } from '../../../server/entities/user.entity';
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public user: User;

  constructor(
    private webRequestInterceptorService: WebRequestInterceptorService,
    private webAuthenticationService: WebAuthenticationService,
    private router: Router,
  ) {}

  async ngOnInit() {
    let serverBaseUrl: string = '';
    if (environment.production) {
      serverBaseUrl = environment.apiEndpoint;
    } else if (location.port === '4200' || location.port === '4201' || location.port === '8100' || location.port === '8101') {
      serverBaseUrl = 'http://' + location.host.replace(/:[0-9]{4}/, ':3000');
    }

    await Nanium.addManager(new NaniumConsumerBrowserHttp({
      apiUrl: serverBaseUrl + '/api',
      apiEventUrl: serverBaseUrl + '/events',
      onServerConnectionRestored: () => {
        console.log('server connection restored');
        // when the clients could not reach the server for some time,
        // you should reload all stuff you need because maybe you missed some events
      },
      eventSubscriptionSendInterceptors: [],
      requestInterceptors: [ this.webRequestInterceptorService ],
      handleError: async (error: any) => {
        console.log(error, this.router.url);
        if (
          error === 'unauthorized'
          || error instanceof Error && error.message == 'unauthorized'
        ) {
          if (this.router.url === '/login') {
            return;
          }
          await this.router.navigate(['/onboarding']);
        }
      }
    }));

    await this.webAuthenticationService.init();
    this.user = await this.webAuthenticationService.getUser();
  }

  hasUser(): boolean {
    return this.user !== undefined;
  }

  shouldShowFooter(): boolean {
    const currentRoute = this.router.url;
    return !(currentRoute.includes('/onboarding'));
  }
}
