<ion-app>
  <ion-router-outlet></ion-router-outlet>
<!--  <div></div>-->
<!--  <ion-footer *ngIf="shouldShowFooter()">-->
<!--    <ion-toolbar>-->
<!--      <ion-buttons style="justify-content: center;gap: 30px;">-->
<!--        <ion-button class="footer-button" color="dark" routerLink="/home" routerLinkActive="active" #rla="routerLinkActive">-->
<!--          <ion-icon slot="icon-only" [name]="rla.isActive ? 'home' : 'home-outline'"></ion-icon>-->
<!--        </ion-button>-->
<!--        <ion-button class="footer-button" color="dark" routerLink="/my-facility" routerLinkActive="active" #rla2="routerLinkActive" *ngIf="hasUser()">-->
<!--          <ion-icon slot="icon-only" [name]="rla2.isActive ? 'storefront' : 'storefront-outline'"></ion-icon>-->
<!--        </ion-button>-->
<!--        <ion-button class="footer-button" color="dark" routerLink="/likes" routerLinkActive="active" #rla3="routerLinkActive" *ngIf="hasUser()">-->
<!--          <ion-icon slot="icon-only" [name]="rla3.isActive ? 'heart' : 'heart-outline'"></ion-icon>-->
<!--        </ion-button>-->
<!--        <ion-button class="footer-button" color="dark" routerLink="/my-profile" routerLinkActive="active" #rla4="routerLinkActive" *ngIf="hasUser()">-->
<!--          <ion-icon slot="icon-only" [name]="rla4.isActive ? 'person-circle' : 'person-circle-outline'"></ion-icon>-->
<!--        </ion-button>-->
<!--        <ion-button class="footer-button" color="dark" routerLink="/login" routerLinkActive="active" #rla4="routerLinkActive" *ngIf="!hasUser()">-->
<!--          <ion-icon slot="icon-only" name="log-in-outline"></ion-icon>-->
<!--        </ion-button>-->
<!--      </ion-buttons>-->
<!--    </ion-toolbar>-->
<!--  </ion-footer>-->
</ion-app>
