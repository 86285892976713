import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'my-facility',
    loadChildren: () => import('./my-facility/my-facility.module').then( m => m.MyFacilityPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'my-profile',
    loadChildren: () => import('./my-profile/my-profile.module').then( m => m.MyProfilePageModule)
  },
  {
    path: 'likes',
    loadChildren: () => import('./likes/likes.module').then( m => m.LikesPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./user/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./user/onboarding/onboarding.module').then( m => m.OnboardingPageModule)
  },
  {
    path: 'consulting-finder',
    loadChildren: () => import('./pages/consulting-finder/consulting-finder.module').then( m => m.ConsultingFinderPageModule)
  },
  {
    path: 'consulting-finder-frontpage',
    loadChildren: () => import('./pages/consulting-finder-frontpage/consulting-finder-frontpage.module').then( m => m.ConsultingFinderFrontpagePageModule)
  },
  {
    path: 'gathering/:id',
    loadChildren: () => import('./pages/gathering/gathering.module').then( m => m.GatheringPageModule)
  },
  {
    path: 'nearby-places',
    loadChildren: () => import('./pages/nearby-places/nearby-places.module').then( m => m.NearbyPlacesPageModule)
  },
  {
    path: 'facility/:id',
    loadChildren: () => import('./pages/facility/facility.module').then( m => m.FacilityPageModule),
  },
  {
    path: 'news/:id',
    loadChildren: () => import('./pages/news/news.module').then( m => m.NewsPageModule)
  },
  {
    path: 'life-hack/:id',
    loadChildren: () => import('./pages/life-hack/life-hack.module').then( m => m.LifeHackPageModule)
  },
  {
    path: 'image-upload-button',
    loadChildren: () => import('./util/image-upload-button/image-upload-button.module').then( m => m.ImageUploadButtonPageModule)
  },
  {
    path: 'search-results',
    loadChildren: () => import('./util/search-results/search-results.module').then( m => m.SearchResultsPageModule)
  },
  {
    path: 'video-upload',
    loadChildren: () => import('./util/video-upload/video-upload.module').then( m => m.VideoUploadPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
